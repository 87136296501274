import React from 'react';
import { css, styled } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

interface CloseButtonProps {
  top?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const StyledIconButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'top' }) <{ top: string }>`
  position: absolute;
  right: 10px;
  color: #fff;

  ${(props) => css`
    top: ${props.top};
  `}
`;

const CloseButton: React.FC<CloseButtonProps> = ({ top = '12px', onClick }) => {
  return (
    <StyledIconButton
      aria-label="close"
      onClick={onClick}
      top={top}
    >
      <CloseIcon />
    </StyledIconButton>
  );
};

export default CloseButton;