import { styled } from '@mui/material/styles';

import Menu from '@mui/material/Menu';

const RoundMenu = styled(Menu)`
    & .MuiPaper-root {
      border-radius: 15px;
      border: 1px solid rgba(255, 255, 255, 0.12) !important;
    }
`;

export default RoundMenu;