import { plPL } from '@mui/x-date-pickers/locales';

import { APP_URL } from "../config";

export const LANGUAGE = navigator.language || navigator.userLanguage;
export const LANGUAGE_CODE = LANGUAGE.split('-')[0];
export const DATEPICKER_LOCALES = {
  pl: plPL,
};

export const DOWNLOAD_URL = `${APP_URL}/download`;

export const RANGE_CALENDAR_DATE_FORMAT = 'yyyy-MM-dd';