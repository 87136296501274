import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupIcon from '@mui/icons-material/Group';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Login from '@mui/icons-material/Login';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SettingsIcon from '@mui/icons-material/Settings';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import AuthContext from '../../context/AuthContext';

import {
  setBottomBarValue,
  setDrawerRightOpen,
} from '../../features/app/appSlice';

import {
  handleLogin,
  handleLogout,
  handleNetworkClick,
  handleProfileClick,
  handleSettingsClick,
} from '../../utils/handlers';
import {
  closeDrawer,
  vhToPx,
} from '../../utils/utils';

import DrawerItems from './DrawerItems';
import ShareLocationDrawerItem from './ShareLocationDrawerItem.tsx';
import VersionInfo from './VersionInfo.tsx';

export default function DrawerRight() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [helpExpanded, setHelpExpanded] = useState(JSON.parse(localStorage.getItem('help-expanded')));

  const { user, logoutUser } = useContext(AuthContext);
  const userPosition = useSelector((state) => state.userPosition.value);

  const friendsRequests = useSelector((state) => state.network.friendsRequests);

  const anyDialogOpen = useSelector((state) => state.dialogs.anyDialogOpen);
  const open = useSelector((state) => state.app.drawerRightOpen);
  const [exited, setExited] = useState(true);
  const [_exited, _setExited] = useState(true);
  const [boxHeight, setBoxHeight] = useState(0);

  const resetMenus = () => {
    dispatch(setBottomBarValue(1));
  };

  const openDrawer = () => {
    dispatch(setDrawerRightOpen(true));
  };

  const handleChange = () => {
    setHelpExpanded(!helpExpanded);
  };

  const _handleProfileClick = () => {
    handleProfileClick(dispatch, resetMenus);
    closeDrawer(dispatch);
  };

  const _handleNetworkClick = () => {
    handleNetworkClick(dispatch, resetMenus);
    closeDrawer(dispatch);
  };

  const _handleLoginClick = () => {
    handleLogin(dispatch, resetMenus);
    closeDrawer(dispatch);
  };

  const _handleSettingsClick = () => {
    handleSettingsClick(dispatch, resetMenus);
    closeDrawer(dispatch);
  };

  const _handleLogoutClick = () => {
    handleLogout(dispatch, resetMenus, logoutUser, t);
    closeDrawer(dispatch);
  };

  const handleResize = () => {
    const versionInfoHeight = document.getElementById('version-info').offsetHeight;
    setBoxHeight(window.innerHeight - versionInfoHeight - vhToPx(8));
  };

  useEffect(() => {
    localStorage.setItem('help-expanded', helpExpanded);
  }, [helpExpanded]);

  useEffect(() => {
    setHelpExpanded(JSON.parse(localStorage.getItem('help-expanded')));
  }, [localStorage.getItem('help-expanded')]);

  useEffect(() => {
    if (document.getElementById('version-info')) {
      window.addEventListener('resize', handleResize);
      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [document.getElementById('version-info')]);

  return (
    <SwipeableDrawer
      anchor="right"
      disableDiscovery={true}
      disableSwipeToOpen={true}
      BackdropProps={{
        // invisible: true,
        style: {
          // top: anyDialogOpen || open ? '8vh' : '9vh',
          // top: '8vh',
          top: _exited && !anyDialogOpen ? '9vh' : '8vh',
          // transition: `top ${open ? 0.195 : 0.225}s ease, left ${open ? 0.195 : 0.225}s ease`,
          transition: 'none',
        },
      }}
      open={open}
      onOpen={openDrawer}
      onClose={() => closeDrawer(dispatch)}
      PaperProps={{
        style: {
          // top: anyDialogOpen || open ? '8vh' : '9vh',
          // top: '8vh',
          top: exited ? '9vh' : '8vh',
          width: '80%',
          backgroundImage: 'unset',
          // transition: `top ${open ? 0.195 : 0.225}s ease, left ${open ? 0.195 : 0.225}s ease`,
          backgroundColor: '#171717',
          boxShadow: 'unset',
        }
      }}
      SlideProps={{
        onEntering: () => {
          _setExited(false);
          setExited(false);
        },
        onExiting: () => {
          _setExited(true);
          setTimeout(() => {
            setExited(true);
          }, 100);
        }
      }}
    // transitionDuration={{ enter: 200, exit: 175 }}
    // variant="persistent"
    >
      <Box
        role="presentation"
        sx={{
          width: '100%',
          maxHeight: `${boxHeight}px`,
          overflow: 'auto',
        }}
      >
        <List>
          {user ? (
            <React.Fragment>
              <ListItem key="Profile" disablePadding onClick={_handleProfileClick}>
                <ListItemButton>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Profile')}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem key="Network" disablePadding onClick={_handleNetworkClick}>
                <ListItemButton>
                  <ListItemIcon>
                    <Badge badgeContent={friendsRequests.length} color="primary">
                      <GroupIcon />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary={t('My community')} />
                </ListItemButton>
              </ListItem>
              {isMobile && userPosition.length === 2 && (
                <React.Fragment>
                  <Divider />
                  <ShareLocationDrawerItem />
                </React.Fragment>
              )}
            </React.Fragment>
          ) : (
            <ListItem key="Login" disablePadding onClick={_handleLoginClick}>
              <ListItemButton>
                <ListItemIcon>
                  <Login />
                </ListItemIcon>
                <ListItemText primary={t('Log in')} />
              </ListItemButton>
            </ListItem>
          )}
          <Divider />
          <Accordion
            onChange={handleChange}
            expanded={helpExpanded}
            sx={{
              backgroundColor: '#171717',
              backgroundImage: 'unset',
              boxShadow: 'unset',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                minHeight: '48px !important',
                '& .MuiAccordionSummary-content': {
                  margin: '0px !important',
                },
              }}
            >
              <Box sx={{ minWidth: '56px' }}>
                <QuestionMarkIcon />
              </Box>
              {t('Info and help')}
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <DrawerItems />
            </AccordionDetails>
          </Accordion>
          {user && (
            <React.Fragment>
              <ListItem key="Settings" disablePadding onClick={_handleSettingsClick}>
                <ListItemButton>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('Settings')} />
                </ListItemButton>
              </ListItem>
              <ListItem key="Logout" disablePadding onClick={_handleLogoutClick}>
                <ListItemButton>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <ListItemText primary={t('Log out')} />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          )}
        </List>
      </Box>
      <VersionInfo bottom="8vh" />
    </SwipeableDrawer>
  );
}
