import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';

import CloseButton from './CloseButton.tsx';
import PaperComponent from '../PaperComponent';

import { borderedDialogPaperProps } from '../../theme';

import { setPremiumInfoDialogOpen } from '../../features/app/dialogsSlice';

import { CONTACT_EMAIL } from '../../config';

export default function PremiumInfoDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state) => state.dialogs.premiumInfoDialogOpen);

  const handleClose = () => {
    dispatch(setPremiumInfoDialogOpen(false));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      {...borderedDialogPaperProps}
    >
      <DialogTitle textAlign="center" style={{ cursor: 'move' }} id="draggable-dialog-title">
        {t('Premium account required')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <DialogContent>
        <DialogContentText variant="subtitle2">
          {t('If you are interested in premium features, \
please feel free to contact our sales team \
using')} <Link href="/contact/">{t('contact form')}</Link> {t('or contact \
email')} <Link href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Link>.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
};