import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LabelIcon from '@mui/icons-material/Label';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';
import Paper from '@mui/material/Paper';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

import dayjs from 'dayjs';

import AuthContext from '../../context/AuthContext';
import PaperComponent from '../PaperComponent';

import { setEventDialogOpen } from '../../features/dialogs/eventDialogSlice';
import {
  setImageDialogAlt,
  setImageDialogOpen,
  setImageDialogSrc,
} from '../../features/dialogs/imageDialogSlice';
import { setShowDirectionsControl } from '../../features/map/mapSlice';

import {
  formatDistance,
  getDistance,
} from '../../utils/mapUtils';

import {
  customDialogStyle,
  grayedOutFilter,
  noFullScreenDialogPaperProps,
  WIDTH_BREAKPOINT,
} from '../../theme';

import BookmarkButton from '../ui/BookmarkButton';
import CategoryChipsWrapper from './CategoryChipsWrapper.tsx';
import CloseButton from '../ui/CloseButton.tsx';
import CopyLinkButton from '../ui/CopyLinkButton.tsx';
import EventMenu from './EventMenu';
import InviteFriendsButton from '../ui/InviteFriendsButton';
import ShareButton from '../ui/ShareButton.tsx';
import ShowOnMapButton from '../ui/ShowOnMapButton';

import { APP_URL } from '../../config';
import {
  EMPTY_FEATURE,
  GOOGLE_MAPS_URL,
} from '../../consts/map';


const DirectionsButton = styled(Button)`
  width: 6.5rem;
`;

const MenuButton = styled(Button)`
  padding: 0;
  min-width: unset !important;
  width: 1.5rem;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 0.5rem;
  min-height: 1rem !important;

  :hover {
    border-radius: 10px;
  }
`;

const StyledMenuList = styled(MenuList)`
  background-image: linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12));
  border-radius: 10px;
  padding: 0;
`;

const StyledPaper = styled(Paper)`
  margin-top: 0.5rem;
`;

const StyledPopper = styled(Popper)`
  z-index: 11;
`;

export default function EventDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const width = useSelector((state) => state.app.width);

  const open = useSelector((state) => state.eventDialog.open);
  const _event = useSelector((state) => state.eventDialog.event);
  const event = useSelector((state) =>
    state.events.value.features.find(f => f.properties.uuid === _event.properties.uuid) ||
    state.savedEvents.value.features.find(f => f.properties.uuid === _event.properties.uuid) ||
    state.userEvents.value.features.find(f => f.properties.uuid === _event.properties.uuid) ||
    _event ||
    EMPTY_FEATURE
  );

  const userPosition = useSelector((state) => state.userPosition.value);
  const map = useSelector((state) => state.map.value);
  const directionsControl = useSelector((state) => state.map.directionsControl);

  const [directionsMenuOpen, setDirectionsMenuOpenOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleClose = () => {
    dispatch(setEventDialogOpen(false));
  };

  const handleMenuClose = () => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    };

    setDirectionsMenuOpenOpen(false);
  };

  const handleToggle = () => {
    setDirectionsMenuOpenOpen((prevOpen) => !prevOpen);
  };

  const handleEventPictureClick = () => {
    dispatch(setImageDialogSrc(event.properties.picture));
    dispatch(setImageDialogAlt(event.properties.name));
    dispatch(setImageDialogOpen(true));
  };

  const handleNavigateClick = () => {
    map.setBearing(0, { geolocateSource: true });
    map.setPitch(0, { geolocateSource: true });

    directionsControl.setOrigin(userPosition);
    directionsControl.setDestination(event.geometry.coordinates);

    /* reorder layers in order to display points above lines */
    map.moveLayer('directions-origin-point', 'directions-destination-point');
    map.moveLayer('directions-route-line', 'directions-origin-point');
    map.moveLayer('directions-route-line-casing', 'directions-route-line');
    map.setLayoutProperty('directions-destination-point', 'visibility', 'visible');

    dispatch(setShowDirectionsControl(true));
    dispatch(setEventDialogOpen(false));
  };

  useEffect(() => {
    if (Object.keys(event.properties).length === 0) handleClose();
  }, [event]);

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      onClose={handleClose}
      fullWidth
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          ...noFullScreenDialogPaperProps.PaperProps.style,
          maxHeight: width <= WIDTH_BREAKPOINT ? '70vh' : 'unset',
        }
      }}
      sx={{ ...customDialogStyle.sx }}
      BackdropProps={{
        style: {
          top: width <= WIDTH_BREAKPOINT ? undefined : '8vh',
        }
      }}
    >
      <DialogTitle
        id="draggable-dialog-title"
        textAlign="center"
        sx={{
          cursor: 'move',
          wordBreak: 'break-word',
        }}>
        {t('Pin details')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <DialogContent sx={{ padding: '0 1.5rem' }} onScroll={handleMenuClose}>
        <Badge
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={event.properties.invited ? <MarkEmailReadIcon sx={{ fontSize: '1rem' }} /> : ''}
          sx={{ width: '100%' }}
        >
          <Box display="flex" justifyContent="center" sx={{ width: '100%' }}>
            {event.properties.picture ? (
              <img src={event.properties.picture} loading="lazy"
                onClick={handleEventPictureClick}
                style={{
                  maxWidth: '100%',
                  aspectRatio: '16 / 9',
                  objectFit: 'cover',
                  borderRadius: '10px',
                }}
              />
            ) : (
              <Box sx={{
                width: '100%',
                aspectRatio: '16 / 9',
                objectFit: 'cover',
                borderRadius: '10px',
                border: '1px solid #fff',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <Avatar
                  aria-label="recipe"
                  sx={{
                    bgcolor: 'transparent',
                    width: '48px',
                    height: '48px',
                    borderRadius: 0,
                  }}
                >
                  <PanoramaOutlinedIcon sx={{ color: '#fff', fontSize: '48px' }} />
                </Avatar>
              </Box>
            )}
          </Box>
        </Badge>
        <Grid container sx={{ paddingTop: '1rem', alignItems: 'center' }}>
          <Grid item xs={10}>
            <Typography
              variant="h6"
              sx={{ wordBreak: 'break-word' }}
            >
              {event.properties.name}
            </Typography>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="end"
            sx={{ position: 'relative', right: '-16px' }}
          >
            {user && (
              <EventMenu
                feature={event}
                edit={event.properties.own}
                end={event.properties.own && ['ongoing', 'upcoming'].includes(event.properties.timeline)}
                cancel={event.properties.own}
                delete={event.properties.own}
                report={!event.properties.own}
              />
            )}
          </Grid>
        </Grid>
        <Typography variant="caption">
          {event.properties.own ? (
            event.properties.private ? t('Your private pin') : t('Your public pin')
          ) : (
            !event.properties.anonymous && event.properties.user ? (
              <>
                <span style={{
                  filter: event.properties.advertiserDeleted && grayedOutFilter,
                  display: 'inline-flex',
                  alignItems: 'center',
                  verticalAlign: 'middle',
                }}>
                  {event.properties.advertiserDeleted && <PersonOffIcon fontSize="smaller" />}{event.properties.user}
                </span>&nbsp;<span style={{ verticalAlign: 'middle' }}>{`- ${event.properties.private ? t('private pin') : t('public pin')}`}</span>
              </>
            ) : (
              event.properties.private ? t('Private pin') : t('Public pin')
            )
          )}
        </Typography>
        {userPosition.length > 0 && (
          <DialogContentText>
            {formatDistance(getDistance(event.geometry.coordinates, userPosition))}
          </DialogContentText>
        )}
        <DialogContentText>
          {`${dayjs(event.properties.start * 1000).format('L LT')} \
                      - ${dayjs(event.properties.end * 1000).format('L LT')}`}
        </DialogContentText>
        {event.properties.categories?.length > 0 && (
          <CategoryChipsWrapper>
            {event.properties.categories.map((category, idx) => (
              <Chip icon={<LabelIcon />}
                size="small"
                label={category.name}
                key={idx}
                sx={{
                  marginLeft: idx === 0 ? '1.5rem' : undefined,
                  marginRight: idx === event.properties.categories.length - 1 ? '1.5rem !important' : undefined,
                }}
              />
            ))}
          </CategoryChipsWrapper>
        )}
        <Grid container sx={{ padding: '1rem 0', marginLeft: '-12px' }}>
          <Grid item>
            {(event.properties.own || !event.properties.private) && (
              navigator.canShare ? (
                <ShareButton event={event} />
              ) : (
                <CopyLinkButton link={`${APP_URL}/?pin=${event.properties.uuid}`} />
              )
            )}
          </Grid>
          <Grid item>
            {window.location.pathname === '/' && (
              <ShowOnMapButton feature={event} />
            )}
          </Grid>
          <Grid item>
            {user && (event.properties.own || event.properties.othersCanInvite) && (
              <InviteFriendsButton event={event} />
            )}
          </Grid>
          <Grid item />
          <Grid item />
          <Grid item display="flex" justifyContent="end"
            sx={{ position: 'relative', right: '-27px', marginLeft: 'auto' }}>
            {!event.properties.own && (
              <BookmarkButton event={event} />
            )}
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          color="text.secondary"
          align="justify"
          sx={{
            display: '-webkit-box',
            overflowY: 'auto',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 6,
            wordBreak: 'break-word',
          }}>
          {event.properties.description}
        </Typography>
        <Grid container p={2}>
          <Grid item xs={2} />
          <Grid item xs={8} display="flex" justifyContent="center" >
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="Button group with a nested menu"
            >
              <DirectionsButton
                variant="contained"
                disabled={userPosition.length !== 2}
                onClick={handleNavigateClick}
              >
                {t('Directions')}
              </DirectionsButton>
              <MenuButton
                size="small"
                aria-controls={directionsMenuOpen ? 'split-button-menu' : undefined}
                aria-expanded={directionsMenuOpen ? 'true' : undefined}
                aria-label="directions menu"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                {directionsMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </MenuButton>
            </ButtonGroup>
            <StyledPopper
              open={directionsMenuOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <StyledPaper>
                    <ClickAwayListener onClickAway={handleMenuClose}>
                      <StyledMenuList id="split-button-menu" autoFocusItem>
                        <StyledLink
                          href={`${GOOGLE_MAPS_URL}?q=${event.geometry.coordinates[1]},${event.geometry.coordinates[0]}`}
                          target="_blank"
                          underline="none"
                          color="inherit"
                          onClick={handleMenuClose}
                        >
                          <StyledMenuItem>
                            <Typography variant="caption">
                              {t('Show on Google Maps')}
                            </Typography>
                          </StyledMenuItem>
                        </StyledLink>
                      </StyledMenuList>
                    </ClickAwayListener>
                  </StyledPaper>
                </Grow>
              )}
            </StyledPopper>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="end" alignItems="center" />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
