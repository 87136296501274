import React from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';

import { borderedDialogPaperProps } from '../../theme';

export default function NewEventNotAllowedDialog(props) {
  const { t } = useTranslation();

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        {...borderedDialogPaperProps}
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              color: 'text.primary',
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContentText>
            {t('It seems like you are trying to create a pin outside circle. \
Move closer to desired location.')}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}