import React from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';

import CenteredBox from './CenteredBox.tsx';
import DrawerItems from './DrawerItems';
import TextLogo from './TextLogo.tsx';
import VersionInfo from './VersionInfo.tsx';
import ShareLocationDrawerItem from './ShareLocationDrawerItem.tsx';

export default function DrawerLeft() {
  const userPosition = useSelector((state) => state.userPosition.value);

  return (
    <Drawer
      anchor={'left'}
      BackdropProps={{ invisible: true }}
      open={true}
      variant="permanent"
      PaperProps={{ style: { zIndex: 10 } }}
    >
      <Box
        role="presentation"
        sx={{ width: 250 }}
      >
        <CenteredBox sx={{ height: '8vh', minHeight: '64px' }}>
          <TextLogo justify="center" />
        </CenteredBox>
        <Divider />
        <List sx={{ paddingTop: 0 }}>
          {isMobile && userPosition.length === 2 && (
            <React.Fragment>
              <ShareLocationDrawerItem />
              <Divider />
            </React.Fragment>
          )}
          <DrawerItems />
        </List>
        <VersionInfo bottom={0} />
      </Box>
    </Drawer>
  );
}
