import { createTheme } from '@mui/material/styles';

export const WIDTH_BREAKPOINT = 900;
export const DRAWER_WIDTH = 250;
export const mainColor = '#0097B2';
export const customTheme = createTheme({
  typography: {
    fontFamily: ['Asap'],
  },
  palette: {
    mode: 'dark',
    primary: {
      main: mainColor,
    },
    secondary: {
      main: mainColor,
    },
    background: {
      default: '#060505',
    },
    error: {
      main: '#f11616',
    },
    info: {
      main: '#38afdc',
    },
    warning: {
      main: '#ff7c1c',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: 'unset',
          backgroundImage: 'linear-gradient(#171717, #191919)',
          // https://stackoverflow.com/questions/53772429/material-ui-how-can-i-style-the-scrollbar-with-css-in-js
          scrollbarColor: "#6b6b6b #2b2b2b",
          scrollbarWidth: 'auto',
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
            width: 10,
            height: 10,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
    // MuiSnackbar: {
    //   styleOverrides: {
    //     root: {
    //       border: `solid 1px ${mainColor}`,
    //       borderRadius: '3px',
    //     },
    //   },
    // },
  },
});

export const customDialogStyle = {
  sx: {
    top: '8vh',
    // bottom: '7vh',
    zIndex: 10,
  },
  // PaperProps: {
  //   style: { borderRadius: '25px' }
  // },
  BackdropProps: {
    sx: {
      top: '8vh',
    }
  }
  // slotProps={{
  //   backdrop: {
  //     sx: {
  //       top: '8vh',
  //     }
  //   }
  // }}
};

export const noFullScreenDialogPaperProps = {
  PaperProps: {
    style: {
      borderRadius: '25px',
      top: '-4vh',
      // position: 'absolute',
      // bottom: '10vh',
    }
  }
};

export const borderedDialogPaperProps = { PaperProps: { style: { borderRadius: '25px' } } };

export const navbarBoxShadow = '0px 3px 3px -2px rgb(0 151 178 / 20%), 0px 3px 4px 0px rgb(0 151 178 / 14%), 0px 1px 8px 0px rgb(0 151 178 / 12%)';
export const reversedNavbarBoxShadow = '0px 1px 8px 0px rgb(0 151 178 / 12%), 0px 3px 4px 0px rgb(0 151 178 / 14%), 0px 3px 3px -2px rgb(0 151 178 / 20%)';
export const fabBoxShadow = '0px 3px 3px -2px rgb(0 151 178 / 80%), 0px 3px 4px 0px rgb(0 151 178 / 56%), 0px 1px 8px 0px rgb(0 151 178 / 48%)';
export const reversedFabBoxShadow = '0px -1px 8px 0px rgb(0 151 178 / 48%), 0px -3px 4px 0px rgb(0 151 178 / 56%), 0px -3px 3px -2px rgb(0 151 178 / 80%)';
export const equalBoxShadow = '0 0 8px rgb(0 151 178)';
export const grayedOutFilter = 'brightness(0.5) grayscale(100%)';
export const sunsetOrange = '#FA5F55';
export const mediumSeaGreen = '#66BB6A';
export const brightOrange = '#FFAC1C';
export const backdropBlur = 'blur(5px)';
export const errorBoxShadow = '0px 3px 3px -2px rgb(255 0 0 / 80%), 0px 3px 4px 0px rgb(255 0 0 / 56%), 0px 1px 8px 0px rgb(255 0 0 / 48%)';
export const whiteBoxShadow = '0 2px 4px rgba(255, 255, 255, 0.5), 0 0 6px rgba(255, 255, 255, 0.3)';
export const equalWhiteBoxShadow = '0 0 4px rgba(255, 255, 255, 0.75)';
export const dimGray = '#9E9E9E';