import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

import api from '../../utils/api.ts';
import { arraysAreEqual } from '../../utils/utils';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import {
  // resetInviteFriendsDialogEvent,
  setInviteFriendsDialogOpen,
} from '../../features/dialogs/inviteFriendsDialogSlice';
import { setFriends } from '../../features/network/networkSlice';

import {
  backdropBlur,
  borderedDialogPaperProps,
  fabBoxShadow,
  WIDTH_BREAKPOINT,
} from '../../theme';

import InviteFriends from '../user/InviteFriends';
import PaperComponent from '../PaperComponent';
import SmallLoader from '../ui/SmallLoader.tsx';
import CloseButton from '../ui/CloseButton.tsx';

export default function InviteFriendsDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const width = useSelector((state) => state.app.width);

  const friends = useSelector((state) => state.network.friends);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [initiallySelectedFriends, setInitiallySelectedFriends] = useState([]);
  const [alreadyInvited, setAlreadyInvited] = useState([]);
  const [invitationMessage, setInvitationMessage] = useState('');

  const [showAlert, setShowAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [alertText, setAlertText] = useState('');

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const [invitationMessageError, setInvitationMessageError] = useState(false);

  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(true);

  const open = useSelector((state) => state.inviteFriendsDialog.open);
  const event = useSelector((state) => state.inviteFriendsDialog.event);

  const premiumInfoDialogOpen = useSelector((state) => state.dialogs.premiumInfoDialogOpen);

  const handleClose = () => {
    dispatch(setInviteFriendsDialogOpen(false));
    setTimeout(() => {
      setSelectedFriends([]);
      setInitiallySelectedFriends([]);
      setAlreadyInvited([]);
      setInvitationMessage('');
      setSubmitButtonDisabled(true);
      setLoading(true);
      setFetching(true);
    }, 500);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitButtonDisabled) { return };
    setSubmitButtonDisabled(true);
    setLoading(true);
    await api.post('/invitations/new/', {
      'uuid': event.properties.uuid,
      'friends': selectedFriends.map(f => f.properties.uuid),
      'message': invitationMessage,
    }).then(response => {
      setTimeout(() => {
        setSubmitButtonDisabled(false);
        setLoading(false);
      }, 1000);
      dispatch(setInviteFriendsDialogOpen(false));
      dispatch(setAlertsSnackbarSeverity('success'));
      dispatch(setAlertsSnackbarText(t('Invitations have been updated!')));
      dispatch(setAlertsSnackbarOpen(true));

      setTimeout(() => {
        setShowAlert(false);
        setSelectedFriends([]);
        setInitiallySelectedFriends([]);
        setAlreadyInvited([]);
        setInvitationMessage('');
      }, 500);
    }).catch(err => {
      if (err.response.status === 404) {
        setAlertSeverity('error');
        setAlertText(t('No such ongoing event!'));
        setShowAlert(true);
        setTimeout(() => {
          setSubmitButtonDisabled(false);
          setLoading(false);
        }, 3000);
      };
    });
  };

  const getFriends = async () => {
    await api.get('/users/friends/').then(response => {
      dispatch(setFriends(response.data));
    });
  };

  const getInvitations = async () => {
    setLoading(true);
    setFetching(true);
    await api.get(`/event-invitations/${event.properties.uuid}/`).then(response => {
      const filteredFriends = friends.features.filter(feature => response.data.map(el => el.uuid).includes(feature.properties.uuid));
      setSelectedFriends(filteredFriends);
      setInitiallySelectedFriends(filteredFriends);
      setAlreadyInvited(response.data);
      getFriends().then(response => {
        setLoading(false);
        setFetching(false);
      });
    }).catch(err => {
      if (err.response.status === 404) {
        setSubmitButtonDisabled(true);
        setLoading(false);
        setFetching(false);
        setAlertSeverity('error');
        setAlertText(t('No such ongoing event!'));
        setShowAlert(true);
      };
    });
  };

  useEffect(() => {
    if (open) {
      setShowAlert(false);
      getInvitations();
    } else {
      // dispatch(resetInviteFriendsDialogEvent());
      setTimeout(() => {
        setInvitationMessageError(false);
      }, 500);
    };
  }, [open]);

  useEffect(() => {
    setSubmitButtonDisabled(
      arraysAreEqual(
        initiallySelectedFriends.map(f => f.properties.uuid).sort(),
        selectedFriends.map(f => f.properties.uuid).sort(),
      ) ||
      premiumInfoDialogOpen,
    );
  }, [initiallySelectedFriends, selectedFriends]);

  return (
    <Dialog
      open={open}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          ...borderedDialogPaperProps.PaperProps.style,
          height: width <= WIDTH_BREAKPOINT ? '70vh' : '90vh',
          minWidth: width <= WIDTH_BREAKPOINT / 2 ? '90vw' : '30vw',
        }
      }}
    >
      {showAlert ? <Alert severity={alertSeverity}>{alertText}</Alert> : null}
      <DialogTitle textAlign="center" style={{ cursor: 'move' }} id="draggable-dialog-title">
        {t('Invite friends')}
        {loading && <SmallLoader />}
        <DialogContentText id="alert-dialog-description">
          {t('Select friends from list below')}
        </DialogContentText>
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <DialogContent sx={{ paddingBottom: '5rem' }}>
        <form>
          <InviteFriends
            selectedFriends={selectedFriends}
            setSelectedFriends={setSelectedFriends}
            invitationMessage={invitationMessage}
            setInvitationMessage={setInvitationMessage}
            invitationMessageError={invitationMessageError}
            setInvitationMessageError={setInvitationMessageError}
            alreadyInvited={alreadyInvited}
            setAlreadyInvited={setAlreadyInvited}
            disabled={loading}
            fetching={fetching}
            setSubmitButtonDisabled={setSubmitButtonDisabled}
          />
        </form>
      </DialogContent>
      <Stack
        spacing={0}
        direction="row"
        justifyContent="space-around"
        sx={{
          height: '4.5rem',
          padding: '1rem 1rem',
          backgroundColor: 'transparent',
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          backdropFilter: backdropBlur,
          borderRadius: '10px 10px 0 0',
          boxShadow: fabBoxShadow,
        }}
      >
        <Button
          disabled={
            submitButtonDisabled ||
            friends.features.length === 0 ||
            invitationMessageError ||
            loading ||
            fetching
          }
          onClick={handleSubmit}
          type="submit"
          variant="contained"
        >
          {t('Invite')}
        </Button>
      </Stack>
    </Dialog>
  )
};