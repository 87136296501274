import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import stripEmoji from 'emoji-strip';

import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LabelIcon from '@mui/icons-material/Label';
import Menu from '@mui/material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from 'dayjs';

import { a11yProps, TabPanel } from '../ui/Tabs';

import CategoryChipsWrapper from './CategoryChipsWrapper.tsx';
import CloseButton from '../ui/CloseButton.tsx';
import EventPictureUpload from './EventPictureUpload.tsx';
import PaperComponent from '../PaperComponent';
import SmallLoader from '../ui/SmallLoader.tsx';
import TabsWrapper from '../ui/TabsWrapper.tsx';

import { DATEPICKER_LOCALES } from '../../consts/app';
import {
  EVENT_DESCRIPTION_MAX_LENGTH,
  EVENT_NAME_MAX_LENGTH,
} from '../../limits.ts';

import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import { updateUserEvent } from '../../features/events/userEventsSlice';

import {
  backdropBlur,
  customDialogStyle,
  fabBoxShadow,
  noFullScreenDialogPaperProps,
  WIDTH_BREAKPOINT,
} from '../../theme';
import { setEditEventDialogOpen } from '../../features/dialogs/editEventDialogSlice';
import { setEventDialogEvent } from '../../features/dialogs/eventDialogSlice';

import api from '../../utils/api.ts';
import { checkedIcon, icon } from '../../utils/icons';
import { countCharacters } from '../../utils/utils';

export default function EditEventDialog() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const eventsCategories = useSelector(state => state.eventsCategories.value)

  const [eventUuid, setEventUuid] = useState('');
  const [eventName, setEventName] = useState('');
  const [eventPicture, setEventPicture] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [eventStartDatetime, setEventStartDatetime] = useState(dayjs());
  const [eventStartTimestamp, setEventStartTimestamp] = useState(0);
  const [eventEndDatetime, setEventEndDatetime] = useState(dayjs());
  const [eventEndTimestamp, setEventEndTimestamp] = useState(0);
  const [selectedEventCategories, setSelectedEventCategories] = useState([]);
  const [eventAnonymous, setEventAnonymous] = useState(false);
  const [eventPrivate, setEventPrivate] = useState(false);
  const [othersCanInvite, setOthersCanInvite] = useState(true);

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const firstTabRef = useRef(null);
  const nameInputRef = useRef(null);

  const [firstTabHeight, setFirstTabHeight] = useState(0);
  const [firstTabWidth, setFirstTabWidth] = useState(0);
  const width = useSelector((state) => state.app.width);

  const [tabIndex, setTabIndex] = useState(0);

  const [eventNameError, setEventNameError] = useState(false);
  const [eventDescriptionError, setEventDescriptionError] = useState(false);
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');

  const language = useSelector((state) => state.settings.language);

  const open = useSelector((state) => state.editEventDialog.open);
  const event = useSelector((state) => state.editEventDialog.event);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleEventNameChange = (e) => {
    setEventNameError(countCharacters(e.target.value) > EVENT_NAME_MAX_LENGTH);
    // setEventName(stripEmoji(e.target.value));
    setEventName(e.target.value);
  };

  const handleEventDescriptionChange = (e) => {
    setEventDescriptionError(countCharacters(e.target.value) > EVENT_DESCRIPTION_MAX_LENGTH);
    setEventDescription(e.target.value);
  };

  const handleEventStartTimeChange = (newValue) => {
    if (newValue.isAfter(eventEndDatetime)) {
      setStartDateError(t("Start time can't be later than end time"));
    } else {
      setStartDateError('');
    };
    if (newValue.isBefore(eventEndDatetime)) {
      setEndDateError('');
    };

    setEventStartDatetime(newValue);
    setEventStartTimestamp(newValue.unix());
  };

  const handleEventEndTimeChange = (newValue) => {
    if (newValue.isBefore(eventStartDatetime)) {
      setEndDateError(t("End time can't be before start time"));
    } else {
      setEndDateError('');
    };
    if (newValue.isAfter(eventStartDatetime)) {
      setStartDateError('');
    };

    setEventEndDatetime(newValue);
    setEventEndTimestamp(newValue.unix());
  };

  const handleAnonymousChange = (e) => {
    setEventAnonymous(e.target.checked);
  };

  const handlePrivateChange = (e) => {
    setEventPrivate(e.target.checked);
  };

  const handleOthersCanInviteChange = (e) => {
    setOthersCanInvite(e.target.checked);
  };

  const handleClose = () => {
    dispatch(setEditEventDialogOpen(false));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (saveButtonDisabled) return;
    const _eventName = eventName.trim();
    const _eventDescription = eventDescription.trim();
    setEventName(_eventName);
    setEventDescription(_eventDescription);
    if (!eventName || _eventName === '') {
      setTabIndex(0);
      setEventNameError(true);
      return;
    };
    setLoading(true);
    setSaveButtonDisabled(true);

    const formData = new FormData();
    formData.append('uuid', eventUuid);
    formData.append('name', _eventName);
    formData.append('picture', eventPicture);
    formData.append('description', _eventDescription);
    formData.append('start', eventStartTimestamp);
    formData.append('end', eventEndTimestamp);
    formData.append('categories', selectedEventCategories.map(c => c.id));
    formData.append('anonymous', eventAnonymous);
    formData.append('privateEvent', eventPrivate);
    formData.append('othersCanInvite', othersCanInvite);

    await api.post('/events/edit/', formData).then(response => {
      dispatch(setEventDialogEvent(response.data));
      dispatch(updateUserEvent({
        uuid: eventUuid,
        properties: response.data.properties,
      }));

      handleClose();

      dispatch(setAlertsSnackbarSeverity('info'));
      dispatch(setAlertsSnackbarText(t('Pin saved')));
      dispatch(setAlertsSnackbarOpen(true));

      setTimeout(() => {
        setLoading(false);
        setSaveButtonDisabled(false);
      }, 1000);
    }).catch(err => {
      dispatch(setAlertsSnackbarSeverity('error'));
      dispatch(setAlertsSnackbarText(t('Something went wrong.')));
      dispatch(setAlertsSnackbarOpen(true));

      setLoading(false);
      setSaveButtonDisabled(false);
    });
  };

  useEffect(() => {
    if (open) {
      setEventUuid(event.properties.uuid);
      setEventName(event.properties.name);
      setEventPicture(event.properties.picture);
      setEventDescription(event.properties.description);
      setEventStartDatetime(dayjs.unix(event.properties.start));
      setEventStartTimestamp(event.properties.start);
      setEventEndDatetime(dayjs.unix(event.properties.end));
      setEventEndTimestamp(event.properties.end);
      setSelectedEventCategories(event.properties.categories);
      setEventAnonymous(event.properties.anonymous);
      setEventPrivate(event.properties.private);
      setOthersCanInvite(event.properties.othersCanInvite);
    } else {
      setSaveButtonDisabled(false);
    };
  }, [open]);

  useEffect(() => {
    setTabIndex(0);
  }, [open]);

  useEffect(() => {
    if (open) {
      if (firstTabRef.current && firstTabHeight === 0) { setFirstTabHeight(firstTabRef.current.offsetHeight) };
      if (firstTabRef.current && firstTabWidth === 0) { setFirstTabWidth(firstTabRef.current.offsetWidth) };
      const secondTab = document.getElementById('edit-event-dialog-tabpanel-1');

      if (secondTab) {
        secondTab.style.height = `${firstTabHeight}px`;
        secondTab.style.width = `${firstTabWidth}px`;
      };
    };
  }, [open, tabIndex, firstTabRef.current]);

  useEffect(() => {
    setSaveButtonDisabled(
      eventNameError ||
      eventDescriptionError ||
      Boolean(startDateError) ||
      Boolean(endDateError)
    );
  }, [
    eventNameError,
    eventDescriptionError,
    startDateError,
    endDateError,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (nameInputRef.current && !isMobile) nameInputRef.current.focus();
    }, 100);
  }, [open, tabIndex]);

  return (
    <Dialog
      fullScreen={width <= WIDTH_BREAKPOINT}
      open={open}
      PaperComponent={width > WIDTH_BREAKPOINT ? PaperComponent : null}
      hideBackdrop={false}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ ...customDialogStyle.sx, zIndex: 12, height: 'auto', maxHeight: 'unset' }}
      BackdropProps={{ ...customDialogStyle.BackdropProps }}
      PaperProps={width > WIDTH_BREAKPOINT ? {
        style: {
          ...noFullScreenDialogPaperProps.PaperProps.style,
          maxHeight: 'unset'
        }
      } : {}}
    >
      <DialogTitle
        id="draggable-dialog-title"
        textAlign="center"
        sx={{ cursor: 'move' }}
      >
        {t('Edit pin')}
        {loading && <SmallLoader />}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TabsWrapper>
            <Tabs value={tabIndex} onChange={handleChange} aria-label="basic tabs example" centered>
              <Tab icon={<InfoOutlinedIcon />} {...a11yProps(0)} />
              <Tab icon={<SettingsIcon />} {...a11yProps(1)} />
            </Tabs>
          </TabsWrapper>
          <TabPanel
            value={tabIndex}
            parentName="edit-event-dialog"
            index={0}
            refProp={firstTabRef}
          >
            <Box sx={{ marginTop: '1rem' }}>
              <EventPictureUpload
                picture={eventPicture}
                setPicture={setEventPicture}
              />
            </Box>
            <TextField
              id="name"
              inputRef={nameInputRef}
              margin="dense"
              label={t('Name')}
              fullWidth
              variant="standard"
              autoComplete="new-password"
              required
              inputProps={{
                // autoFocus: true,
                maxLength: 64,
              }}
              value={eventName}
              onChange={handleEventNameChange}
              helperText={`${countCharacters(eventName)}/${EVENT_NAME_MAX_LENGTH} ` + t('characters')}
              error={eventNameError}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}
              localeText={DATEPICKER_LOCALES[language]?.components.MuiLocalizationProvider.defaultProps.localeText || undefined}
              adapterLocale={language === 'en' ? 'en-gb' : language}
            >
              <Stack direction="row">
                <DateTimePicker
                  label={t('From')}
                  value={eventStartDatetime}
                  onChange={handleEventStartTimeChange}
                  ampm={language === 'en'}
                  minDateTime={dayjs().subtract(1, 'week')} // rethink
                  maxDateTime={dayjs().add(1, 'week')}
                  renderInput={(params) => <TextField {...params}
                    id="startDatetime"
                    margin="dense"
                    variant="standard"
                    fullWidth
                    required
                    error={Boolean(startDateError)}
                    helperText={startDateError}
                  />}
                  sx={{ borderRadius: '25px' }}
                />
                <DateTimePicker
                  label={t('Till')}
                  value={eventEndDatetime}
                  onChange={handleEventEndTimeChange}
                  ampm={language === 'en'}
                  minDateTime={dayjs()} // rethink
                  maxDateTime={dayjs().add(1, 'week')}
                  renderInput={(params) => <TextField {...params}
                    id="endDatetime"
                    margin="dense"
                    variant="standard"
                    fullWidth
                    required
                    error={Boolean(endDateError)}
                    helperText={endDateError}
                    sx={{ marginLeft: '8px' }}
                  />}
                />
              </Stack>
            </LocalizationProvider>
            <TextField
              id="startTimestamp"
              label="Start timestamp"
              type="number"
              fullWidth
              variant="standard"
              autoComplete="new-password"
              margin="dense"
              value={eventStartTimestamp}
              required
              disabled
              sx={{
                display: 'none',
              }}
              inputProps={{
                // min: 0,
                // max: 0
              }} // TODO: add validation here
            />
            <TextField
              id="endTimestamp"
              label="End timestamp"
              type="number"
              fullWidth
              variant="standard"
              autoComplete="new-password"
              margin="dense"
              value={eventEndTimestamp}
              required
              disabled
              sx={{
                display: 'none',
              }}
              inputProps={{
                // min: 0,
                // max: 0
              }} // TODO: add validation here
            />
            <CategoryChipsWrapper>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={menuOpen ? 'long-menu' : undefined}
                aria-expanded={menuOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{
                  padding: 0,
                  marginRight: '1rem',
                  marginLeft: '1.5rem',
                }}
              >
                <Chip
                  size="small"
                  variant="outlined"
                  sx={{
                    borderStyle: 'dashed',
                    width: '4rem',
                    '& .MuiChip-label': {
                      display: 'flex',
                      verticalAlign: 'middle',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }
                  }}
                  label={<AddIcon />}
                />
              </IconButton>
              {[...selectedEventCategories].reverse().map((c, idx) => (
                <Badge
                  key={c.id}
                  badgeContent={<CancelOutlinedIcon fontSize="small" />}
                  size="small"
                  onClick={() => {
                    setSelectedEventCategories(
                      selectedEventCategories.filter(_c => _c.id !== c.id)
                    );
                  }}
                  sx={{
                    right: '16px',
                    // top: '16px',
                    marginRight: idx === event.properties.categories.length - 1 ? '1rem !important' : undefined,
                  }}
                >
                  <Chip icon={<LabelIcon />}
                    size="small"
                    label={c.name}
                  />
                </Badge>
              ))}
            </CategoryChipsWrapper>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                style: { minWidth: '200px' }
              }}
            >
              <Autocomplete
                open
                disableClearable
                fullWidth
                multiple
                noOptionsText={t('No options')}
                id="categories"
                value={selectedEventCategories}
                onChange={(event, newValue) => setSelectedEventCategories(newValue)}
                options={eventsCategories}
                // disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderTags={() => null}
                // https://stackoverflow.com/questions/61947941/material-ui-autocomplete-warning-the-value-provided-to-autocomplete-is-invalid
                isOptionEqualToValue={(option, value) => option.id === value.id}
                // https://stackoverflow.com/questions/72044394/how-do-i-disable-textfield-input-with-mui-autocomplete/72045200
                renderInput={({ inputProps, ...rest }) => (
                  <TextField
                    {...rest}
                    variant="standard"
                    label={t('Select categories')}
                    inputProps={{ ...inputProps, readOnly: true }}
                    placeholder={t('Select categories')}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                sx={{ paddingX: '0.5rem' }}
              />
            </Menu>
            <TextField
              id="description"
              label={t('Description')}
              margin="dense"
              multiline
              fullWidth
              variant="standard"
              rows={4}
              inputProps={{
                maxLength: 1024,
              }}
              value={eventDescription}
              onChange={handleEventDescriptionChange}
              helperText={`${countCharacters(eventDescription)}/${EVENT_DESCRIPTION_MAX_LENGTH} ` + t('characters')}
              error={eventDescriptionError}
              sx={{ marginBottom: '4.5rem' }}
            />
          </TabPanel>
          <TabPanel value={tabIndex} parentName="edit-event-dialog" index={1}>
            <FormGroup sx={{ marginTop: '0.5rem' }}>
              <FormControlLabel
                control={
                  <Checkbox id="anonymous"
                    checked={eventAnonymous}
                    onChange={handleAnonymousChange}
                  />}
                label={t('Anonymous')}
              />
              <Typography variant="caption" textAlign="left">
                {t('Check this box to keep your nickname hidden in pin details. \
Keep in mind that invitees will still see who sent the invitation.')}
              </Typography>
              <br />
              <FormControlLabel
                control={
                  <Checkbox id="private"
                    checked={eventPrivate}
                    onChange={handlePrivateChange}
                  />}
                label={t('Private')}
              />
              <Typography variant="caption" textAlign="left">
                {t('Check this box to make the pin visible only to users who accept your invitation.')}
              </Typography>
              <br />
              <FormControlLabel
                control={
                  <Checkbox id="others-can-invite"
                    checked={othersCanInvite}
                    onChange={handleOthersCanInviteChange}
                  />}
                label={t('Others can invite')}
              />
              <Typography variant="caption" textAlign="left">
                {t('Check this box to allow other users to send invitations to your pin.')}
                <br />
                {t('If the pin is marked as private, only those who accept the invitation \
will be able to invite others; otherwise, everyone can do so.')}
              </Typography>
            </FormGroup>
          </TabPanel>
        </DialogContent>
        <Stack
          spacing={0}
          direction="row"
          justifyContent="space-around"
          sx={{
            height: '4.5rem',
            padding: '1rem 1rem',
            backgroundColor: 'transparent',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            backdropFilter: backdropBlur,
            borderRadius: '10px 10px 0 0',
            boxShadow: fabBoxShadow,
          }}
        >
          <Button
            variant="contained"
            disabled={saveButtonDisabled}
            type="submit"
            sx={{ width: '5.25rem' }}
          >
            {t('Save')}
          </Button>
        </Stack>
      </form>
    </Dialog>
  )
};