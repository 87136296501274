import { createSlice } from '@reduxjs/toolkit'

import { LANGUAGE_CODE } from '../../consts/app';


const localStorageLanguage = localStorage.getItem('language');

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    shareLocationWith: 2,  // everyone
    keepScreenOn: true,
    newEventsAnonymous: false,
    alwaysShowIntro: null,
    withinMeters: 100,
    language: localStorageLanguage && localStorageLanguage !== '0' ? localStorageLanguage : LANGUAGE_CODE,
  },
  reducers: {
    setShareLocationWith: (state, action) => {
      state.shareLocationWith = action.payload
    },
    setKeepScreenOn: (state, action) => {
      state.keepScreenOn = action.payload
    },
    setNewEventsAnonymous: (state, action) => {
      state.newEventsAnonymous = action.payload
    },
    setAlwaysShowIntro: (state, action) => {
      state.alwaysShowIntro = action.payload
    },
    setWithinMeters: (state, action) => {
      state.withinMeters = action.payload
    },
    setLanguage: (state, action) => {
      state.language = action.payload
    },
    resetSettings: (state) => {
      state.shareLocationWith = 2;
      state.keepScreenOn = true;
      state.newEventsAnonymous = false;
      state.alwaysShowIntro = null;
      // state.withinMeters = 100;
      // state.language = LANGUAGE_CODE;
    },
  },
})

export const {
  resetSettings,
  setAlwaysShowIntro,
  setKeepScreenOn,
  setLanguage,
  setNewEventsAnonymous,
  setShareLocationWith,
  setWithinMeters,
} = settingsSlice.actions;

export default settingsSlice.reducer;