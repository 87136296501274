import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { css, styled } from '@mui/material/styles';

import stripEmoji from 'emoji-strip';

import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LabelIcon from '@mui/icons-material/Label';
import Menu from '@mui/material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from 'dayjs';

import {
  EVENT_DESCRIPTION_MAX_LENGTH,
  EVENT_NAME_MAX_LENGTH,
} from '../../limits.ts';
import { DATEPICKER_LOCALES } from '../../consts/app';
import {
  backdropBlur,
  customDialogStyle,
  fabBoxShadow,
  noFullScreenDialogPaperProps,
  WIDTH_BREAKPOINT,
} from '../../theme';
import { EMPTY_EVENT } from '../../consts/map';
import api from '../../utils/api.ts';
import { checkedIcon, icon } from '../../utils/icons';
import { getAddress } from '../../utils/mapUtils';
import { countCharacters } from '../../utils/utils';

import { a11yProps, TabPanel } from '../ui/Tabs';

import CategoryChipsWrapper from './CategoryChipsWrapper.tsx';
import CenteredBox from '../ui/CenteredBox.tsx';
import CloseButton from '../ui/CloseButton.tsx';
import EventPictureUpload from './EventPictureUpload.tsx';
import InviteFriends from '../user/InviteFriends';
import PaperComponent from '../PaperComponent';
import SmallLoader from '../ui/SmallLoader.tsx';
import TabsWrapper from '../ui/TabsWrapper.tsx';

import {
  setAlertsSnackbarAutoHideDuration,
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../../features/app/alertsSnackbarSlice';
import { setBottomBarValue } from '../../features/app/appSlice';
import {
  setPremiumInfoDialogOpen,
  setUserEventsDialogOpen,
} from '../../features/app/dialogsSlice';
import {
  setNewEventDialogEvent,
  setNewEventDialogOpen,
  setNewEventProperty,
} from '../../features/dialogs/newEventDialogSlice.ts';
import { addUserEvent } from '../../features/events/userEventsSlice';
import { setFriends } from '../../features/network/networkSlice';

const StyledDialogContent = styled(DialogContent, { shouldForwardProp: (prop) => prop !== 'plan' })`
  ${(props) => css`
    padding-top: ${props.plan === 'free' && 0};
  `}
`;

const StyledDivider = styled(Divider)`
  margin: 0 0.25rem;
`;

const StyledIconButton = styled(IconButton)`
  margin-top: 0.1rem;
`;

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  font-size: 1rem;
`;

const StyledSkeleton = styled(Skeleton)`
  margin-bottom: 0.1rem;
  transform: scale(1, 0.5);
`;

const StyledTypography = styled(Typography, { shouldForwardProp: (prop) => prop !== 'limitsLoading' })`
  display: flex;
  align-items: center;

  ${(props) => css`
    margin-top: ${!props.limitsLoading && '0.2rem'};
  `}
`;

export default function NewEventDialog(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state) => state.newEventDialog.open);
  const newEventsAnonymous = useSelector((state) => state.settings.newEventsAnonymous);

  const [loading, setLoading] = useState(false);

  const initialLoad = useRef(true);
  const firstTabRef = useRef(null);
  const nameInputRef = useRef(null);

  const [firstTabHeight, setFirstTabHeight] = useState(0);
  const [firstTabWidth, setFirstTabWidth] = useState(0);
  const width = useSelector((state) => state.app.width);

  const [tabIndex, setTabIndex] = useState(0);
  const [tabDisabled, setTabDisabled] = useState(true);

  const [eventNameError, setEventNameError] = useState(false);
  const [eventDescriptionError, setEventDescriptionError] = useState(false);
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');

  const [invitationMessage, setInvitationMessage] = useState('');
  const [invitationMessageError, setInvitationMessageError] = useState(false);

  const eventsCategories = useSelector(state => state.eventsCategories.value)

  const language = useSelector((state) => state.settings.language);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const map = useSelector((state) => state.map.value);
  const event = useSelector(state => state.newEventDialog.event);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [selectedFriends, setSelectedFriends] = useState([]);

  const plan = useSelector((state) => state.account.plan);
  const [limits, setLimits] = useState({ day: null, month: null });
  const [limitsLoading, setLimitsLoading] = useState(true);
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const premiumInfoDialogOpen = useSelector((state) => state.dialogs.premiumInfoDialogOpen);

  const handleClose = () => {
    props.newEventMapMarker.remove();
    dispatch(setNewEventDialogOpen(false));
    setTimeout(() => {
      setSubmitButtonDisabled(false);
      setSelectedFriends([]);
      setInvitationMessage('');
      dispatch(setNewEventDialogEvent(EMPTY_EVENT));
      setLimitsLoading(true);
    }, 250);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleAnonymousChange = (e) => {
    dispatch(setNewEventProperty({ key: 'anonymous', value: e.target.checked }));
  };

  const handlePrivateChange = (e) => {
    dispatch(setNewEventProperty({ key: 'private', value: e.target.checked }));
  };

  const handleOthersCanInviteChange = (e) => {
    dispatch(setNewEventProperty({ key: 'othersCanInvite', value: e.target.checked }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const _eventName = event.properties.name.trim();
    dispatch(setNewEventProperty({ key: 'name', value: _eventName }));
    dispatch(setNewEventProperty({ key: 'description', value: event.properties.description.trim() }));
    // TODO: check for other required fields too
    if (!event.properties.name || _eventName === '') {
      setEventNameError(true);
      setTabIndex(0);
    };
    if (_eventName !== '') {
      setLoading(true);
      setSubmitButtonDisabled(true);

      const formData = new FormData();
      formData.append('name', event.properties.name.trim());
      formData.append('picture', event.properties.picture);
      formData.append('description', event.properties.description.trim());
      formData.append('address', event.properties.address);
      formData.append('longitude', event.geometry.coordinates[0]);
      formData.append('latitude', event.geometry.coordinates[1]);
      formData.append('start', event.properties.startTimestamp);
      formData.append('end', event.properties.endTimestamp);
      formData.append('categories', event.properties.categories.map(c => c.id));
      formData.append('anonymous', event.properties.anonymous);
      formData.append('privateEvent', event.properties.private);
      formData.append('othersCanInvite', event.properties.othersCanInvite);
      formData.append('friends', selectedFriends.map(f => f.properties.uuid));
      formData.append('message', invitationMessage);
      formData.append('tz', tz);

      // TODO: add response handling
      await api.post('/events/new/', formData).then(response => {
        let currentData = map.getSource('userEvents')._data;
        currentData.features = [...currentData.features, response.data];
        map.getSource('userEvents').setData(currentData);
        dispatch(addUserEvent(response.data));

        dispatch(setUserEventsDialogOpen(false));
        dispatch(setBottomBarValue(1));
        dispatch(setNewEventDialogOpen(false));
        // dispatch(setUserEventsDialogOpen(true));

        setLoading(false);
        handleClose();

        dispatch(setAlertsSnackbarSeverity('success'));
        dispatch(setAlertsSnackbarText(t('Pin created')));
        dispatch(setAlertsSnackbarOpen(true));

        props.newEventMapMarker.remove();

      }).catch(err => {
        if (err.response.status === 403) {
          dispatch(setAlertsSnackbarAutoHideDuration(3000));
          dispatch(setAlertsSnackbarSeverity('warning'));
          dispatch(setAlertsSnackbarText(t('Limit reached, try again later')));
          dispatch(setAlertsSnackbarOpen(true));
        } else {
          dispatch(setAlertsSnackbarSeverity('error'));
          dispatch(setAlertsSnackbarText(t('Something went wrong.')));
          dispatch(setAlertsSnackbarOpen(true));
        };

        setLoading(false);
        setSubmitButtonDisabled(false);
      });
    };
  };

  const handleEventNameChange = (e) => {
    setEventNameError(countCharacters(e.target.value) > EVENT_NAME_MAX_LENGTH);
    // props.setEventName(stripEmoji(e.target.value));
    dispatch(setNewEventProperty({ key: 'name', value: e.target.value }));
  };

  const handleEventDescriptionChange = (e) => {
    setEventDescriptionError(countCharacters(e.target.value) > EVENT_DESCRIPTION_MAX_LENGTH);
    dispatch(setNewEventProperty({ key: 'description', value: e.target.value }));
  };

  const handleEventStartTimeChange = (newValue) => {
    try {
      if (newValue.isAfter(event.properties.endDatetime)) {
        setStartDateError(t("Start time can't be later than end time"));
      } else {
        setStartDateError('');
      };
      if (newValue.isBefore(event.properties.endDatetime)) {
        setEndDateError('');
      };

      dispatch(setNewEventProperty({ key: 'startDatetime', value: newValue }));
      dispatch(setNewEventProperty({ key: 'startTimestamp', value: newValue.unix() }));
    } catch (err) {
      dispatch(setNewEventProperty({ key: 'startDatetime', value: event.properties.startDatetime }));
      dispatch(setNewEventProperty({ key: 'startTimestamp', value: event.properties.startDatetime.unix() }));
    };
  };

  const handleEventEndTimeChange = (newValue) => {
    try {
      if (newValue.isBefore(event.properties.startDatetime)) {
        setEndDateError(t("End time can't be before start time"));
      } else {
        setEndDateError('');
      };
      if (newValue.isAfter(event.properties.startDatetime)) {
        setStartDateError('');
      };

      dispatch(setNewEventProperty({ key: 'endDatetime', value: newValue }));
      dispatch(setNewEventProperty({ key: 'endTimestamp', value: newValue.unix() }));
    } catch (err) {
      dispatch(setNewEventProperty({ key: 'endDatetime', value: event.properties.endDatetime }));
      dispatch(setNewEventProperty({ key: 'endTimestamp', value: event.properties.endDatetime.unix() }));
    };
  };

  const getFriends = async () => {
    await api.get('/users/friends/').then(response => {
      dispatch(setFriends(response.data));
    });
  };

  const getLimits = async () => {
    setLimitsLoading(true);
    await api.get(`/limits/?tz=${tz}`).then(response => {
      setLimits(response.data);
      setLimitsLoading(false);
    });
  };

  useEffect(() => {
    dispatch(setNewEventProperty({ key: 'anonymous', value: newEventsAnonymous }));
    if (open) getFriends();
  }, [open]);

  useEffect(() => {
    setTabIndex(0);
    if (initialLoad.current) {
      if (open) {
        setTimeout(() => setTabDisabled(false), 500);
        initialLoad.current = false;
      };
    };
    if (!open) {
      setTimeout(() => {
        setEventNameError(false);
        setEventDescriptionError(false);
        setInvitationMessageError(false);
      }, 500);
    };
  }, [open]);

  useEffect(() => {
    if (open) {
      if (firstTabRef.current && firstTabHeight === 0) { setFirstTabHeight(firstTabRef.current.offsetHeight) };
      if (firstTabRef.current && firstTabWidth === 0) { setFirstTabWidth(firstTabRef.current.offsetWidth) };
      const secondTab = document.getElementById('new-event-dialog-tabpanel-1');
      const thirdTab = document.getElementById('new-event-dialog-tabpanel-2');

      if (secondTab && thirdTab && width > WIDTH_BREAKPOINT) {
        secondTab.style.height = `${firstTabHeight}px`;
        secondTab.style.width = `${firstTabWidth}px`;
        thirdTab.style.height = `${firstTabHeight}px`;
        thirdTab.style.width = `${firstTabWidth}px`;
      };
    };
  }, [open, tabIndex, firstTabRef.current, width]);

  useEffect(() => {
    setSubmitButtonDisabled(
      loading ||
      limitsLoading ||
      (typeof limits.day === 'number' && limits.day <= 0) ||
      (typeof limits.month === 'number' && limits.month <= 0) ||
      premiumInfoDialogOpen ||
      eventNameError ||
      eventDescriptionError ||
      invitationMessageError ||
      Boolean(startDateError) ||
      Boolean(endDateError)
    );
  }, [
    loading,
    limitsLoading,
    limits,
    premiumInfoDialogOpen,
    eventNameError,
    eventDescriptionError,
    invitationMessageError,
    startDateError,
    endDateError,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (nameInputRef.current && !isMobile) nameInputRef.current.focus();
    }, 100);
  }, [open, tabIndex]);

  useEffect(() => {
    if (open) {
      dispatch(setNewEventProperty({ key: 'startDatetime', value: dayjs() }));
      dispatch(setNewEventProperty({ key: 'endDatetime', value: dayjs().add(2, 'hour') }));
      dispatch(setNewEventProperty({ key: 'startTimestamp', value: dayjs().unix() }));
      dispatch(setNewEventProperty({ key: 'endTimestamp', value: dayjs().add(2, 'hour').unix() }));
    };
  }, [open]);

  const setNewEventAddress = async () => {
    setLoading(true);
    dispatch(setNewEventProperty({ key: 'address', value: await getAddress(...event.geometry.coordinates) }))
    setLoading(false);
  };

  const setPicture = (picture) => {
    dispatch(setNewEventProperty({ key: 'picture', value: picture }));
  };

  useEffect(() => {
    if (open && event.geometry.coordinates.length === 2) {
      setNewEventAddress();
    };
  }, [open, event.geometry.coordinates]);

  useEffect(() => {
    if (open) {
      if (plan === 'free') {
        getLimits();
      } else {
        setLimitsLoading(false);
      };
    };
  }, [open, plan]);

  return (
    <Dialog
      fullScreen={width <= WIDTH_BREAKPOINT}
      open={open}
      PaperComponent={width > WIDTH_BREAKPOINT ? PaperComponent : null}
      hideBackdrop={false}
      onClose={handleClose}
      // aria-labelledby="alert-dialog-title"
      aria-labelledby="draggable-dialog-title"
      aria-describedby="alert-dialog-description"
      // PaperProps={{
      //   style: {
      //     ...noFullScreenDialogPaperProps.PaperProps.style,
      //     maxHeight: width <= WIDTH_BREAKPOINT ? '75vh' : 'unset',
      //   }
      // }}
      // PaperProps={{ style: { height: 'unset' } }}
      sx={{ ...customDialogStyle.sx, zIndex: 12, height: 'auto', maxHeight: 'unset' }}
      // BackdropProps={{ ...customDialogStyle.BackdropProps }}
      PaperProps={width > WIDTH_BREAKPOINT ? {
        style: {
          ...noFullScreenDialogPaperProps.PaperProps.style,
          maxHeight: 'unset'
        }
      } : {}}
    // {...customDialogStyle}
    >
      <DialogTitle
        id="draggable-dialog-title"
        textAlign="center"
        sx={{ cursor: 'move' }}
      >
        {t('New pin')}
        {(loading || limitsLoading) && <SmallLoader />}
        {plan === 'free' && (
          <CenteredBox>
            <StyledTypography variant="caption" limitsLoading={limitsLoading}>
              {t('Left today')}:&nbsp;{!limitsLoading ? limits.day : (
                <StyledSkeleton>
                  <Typography>0</Typography>
                </StyledSkeleton>
              )}
            </StyledTypography>
            <StyledDivider orientation="vertical" flexItem />
            <StyledTypography variant="caption" limitsLoading={limitsLoading}>
              {t('_This month').replace('_', '')}:&nbsp;{!limitsLoading ? limits.month : (
                <StyledSkeleton>
                  <Typography>00</Typography>
                </StyledSkeleton>
              )}
            </StyledTypography>
            {typeof limits.day === 'number' &&
              typeof limits.month === 'number' &&
              (limits.day <= 0 || limits.month <= 0) && (
                <StyledIconButton onClick={() => dispatch(setPremiumInfoDialogOpen(true))}>
                  <StyledInfoOutlinedIcon />
                </StyledIconButton>
              )}
          </CenteredBox>
        )}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <form onSubmit={handleSubmit}>
        <StyledDialogContent plan={plan}>
          <TabsWrapper>
            <Tabs value={tabIndex} onChange={handleChange} aria-label="basic tabs example" centered>
              <Tab icon={<InfoOutlinedIcon />} {...a11yProps(0)} />
              <Tab disabled={tabDisabled} icon={<GroupAddIcon />} {...a11yProps(1)} />
              <Tab disabled={tabDisabled} icon={<SettingsIcon />} {...a11yProps(2)} />
            </Tabs>
          </TabsWrapper>
          <TabPanel
            value={tabIndex}
            parentName="new-event-dialog"
            index={0}
            refProp={firstTabRef}
          >
            <Box sx={{ marginTop: '1rem' }}>
              <EventPictureUpload
                picture={event.properties.picture}
                setPicture={setPicture}
              />
            </Box>
            <TextField
              id="name"
              value={event.properties.name}
              onChange={handleEventNameChange}
              // ref={myRef}
              // ref={focusUsernameInputField}
              inputRef={nameInputRef}
              // ref={useRef("test")}
              // autoFocus
              // focused
              margin="dense"
              // id="username"
              label={t('Name')}
              type="text"
              fullWidth
              variant="standard"
              autoComplete="new-password"
              required
              helperText={`${countCharacters(event.properties.name)}/${EVENT_NAME_MAX_LENGTH} ` + t('characters')}
              error={eventNameError}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}
              localeText={DATEPICKER_LOCALES[language]?.components.MuiLocalizationProvider.defaultProps.localeText || undefined}
              adapterLocale={language === 'en' ? 'en-gb' : language}
            >
              <Stack direction="row">
                <DateTimePicker
                  label={t('From')}
                  value={event.properties.startDatetime}
                  onChange={handleEventStartTimeChange}
                  ampm={language === 'en'}
                  minDateTime={dayjs().subtract(1, 'week')} // rethink
                  maxDateTime={dayjs().add(1, 'week').add(1, 'hour')}
                  renderInput={(params) => <TextField {...params}
                    id="startDatetime"
                    margin="dense"
                    variant="standard"
                    fullWidth
                    required
                    error={Boolean(startDateError)}
                    helperText={startDateError}
                  />}
                  sx={{ borderRadius: '25px' }}
                />
                <DateTimePicker
                  label={t('Till')}
                  value={event.properties.endDatetime}
                  onChange={handleEventEndTimeChange}
                  ampm={language === 'en'}
                  minDateTime={dayjs().subtract(1, 'week')} // rethink
                  maxDateTime={dayjs().add(1, 'week').add(3, 'hour')}
                  renderInput={(params) => <TextField {...params}
                    id="endDatetime"
                    margin="dense"
                    variant="standard"
                    fullWidth
                    required
                    error={Boolean(endDateError)}
                    helperText={endDateError}
                    sx={{ marginLeft: '8px' }}
                  />}
                />
              </Stack>
            </LocalizationProvider>
            <TextField
              id="startTimestamp"
              label="Start timestamp"
              type="number"
              fullWidth
              variant="standard"
              autoComplete="new-password"
              margin="dense"
              value={event.properties.startTimestamp}
              required
              disabled
              sx={{
                display: 'none',
              }}
              inputProps={{
                // min: 0,
                // max: 0
              }} // TODO: add validation here
            />
            <TextField
              id="endTimestamp"
              label="End timestamp"
              type="number"
              fullWidth
              variant="standard"
              autoComplete="new-password"
              margin="dense"
              value={event.properties.endTimestamp}
              required
              disabled
              sx={{
                display: 'none',
              }}
              inputProps={{
                // min: 0,
                // max: 0
              }} // TODO: add validation here
            />
            <CategoryChipsWrapper>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={menuOpen ? 'long-menu' : undefined}
                aria-expanded={menuOpen ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{
                  padding: 0,
                  marginRight: '1rem',
                  marginLeft: '1.5rem',
                }}
              >
                <Chip
                  size="small"
                  variant="outlined"
                  sx={{
                    borderStyle: 'dashed',
                    width: '4rem',
                    '& .MuiChip-label': {
                      display: 'flex',
                      verticalAlign: 'middle',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }
                  }}
                  label={<AddIcon />}
                />
              </IconButton>
              {[...event.properties.categories].reverse().map((c, idx) => (
                <Badge
                  key={c.id}
                  badgeContent={<CancelOutlinedIcon fontSize="small" />}
                  size="small"
                  onClick={() => {
                    dispatch(setNewEventProperty({ key: 'categories', value: event.properties.categories.filter(_c => _c.id !== c.id) }));
                  }}
                  sx={{
                    right: '16px',
                    // top: '16px',
                    marginRight: idx === event.properties.categories.length - 1 ? '1rem !important' : undefined,
                  }}
                >
                  <Chip icon={<LabelIcon />}
                    size="small"
                    label={c.name}
                  />
                </Badge>
              ))}
            </CategoryChipsWrapper>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                style: { minWidth: '200px' }
              }}
            >
              <Autocomplete
                open
                disableClearable
                fullWidth
                multiple
                noOptionsText={t('No options')}
                id="categories"
                value={event.properties.categories}
                onChange={(e, value) => dispatch(setNewEventProperty({ key: 'categories', value: value }))}
                options={eventsCategories}
                // disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderTags={() => null}
                // https://stackoverflow.com/questions/72044394/how-do-i-disable-textfield-input-with-mui-autocomplete/72045200
                renderInput={({ inputProps, ...rest }) => (
                  <TextField
                    {...rest}
                    variant="standard"
                    label={t('Select categories')}
                    inputProps={{ ...inputProps, readOnly: true }}
                    placeholder={t('Select categories')}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                sx={{ paddingX: '0.5rem' }}
              />
            </Menu>
            <TextField
              id="description"
              value={event.properties.description}
              onChange={handleEventDescriptionChange}
              label={t('Description')}
              margin="dense"
              multiline
              fullWidth
              variant="standard"
              rows={4}
              helperText={`${countCharacters(event.properties.description)}/${EVENT_DESCRIPTION_MAX_LENGTH} ` + t('characters')}
              error={eventDescriptionError}
              sx={{ marginBottom: '4.5rem' }}
            />
            <TextField
              id="address"
              disabled
              // ref={myRef}
              // ref={focusUsernameInputField}
              // inputRef={focusUsernameInputField}
              // ref={useRef("test")}
              // autoFocus
              // focused
              margin="dense"
              multiline
              rows={1}
              // id="username"
              label={t('Address')}
              value={event.properties.address}
              // type="email"
              fullWidth
              variant="standard"
              // autoComplete="on"
              required
              inputProps={{
                // autoFocus: true,
                maxLength: 128,
              }}
              sx={{
                display: 'none',
              }}
            />
            <TextField
              id="longitude"
              label="Longitude"
              type="number"
              fullWidth
              variant="standard"
              autoComplete="new-password"
              margin="dense"
              value={event.geometry.coordinates[0]}
              required
              disabled
              sx={{
                display: 'none',
              }}
              inputProps={{
                min: -180,
                max: 180
              }} // TODO: add validation here
            />
            <TextField
              id="latitude"
              label="Latitude"
              type="number"
              fullWidth
              variant="standard"
              autoComplete="new-password"
              margin="dense"
              value={event.geometry.coordinates[1]}
              required
              disabled
              sx={{
                display: 'none',
              }}
              inputProps={{
                min: -90,
                max: 90
              }} // TODO: add validation here
            />
          </TabPanel>
          <TabPanel value={tabIndex} parentName="new-event-dialog" index={1}>
            <Box sx={{ paddingBottom: '4.5rem' }}>
              <InviteFriends
                selectedFriends={selectedFriends}
                setSelectedFriends={setSelectedFriends}
                invitationMessage={invitationMessage}
                setInvitationMessage={setInvitationMessage}
                invitationMessageError={invitationMessageError}
                setInvitationMessageError={setInvitationMessageError}
                alreadyInvited={[]}
                setAlreadyInvited={() => { }}
                setSubmitButtonDisabled={() => { }}
              />
            </Box>
          </TabPanel>
          <TabPanel value={tabIndex} parentName="new-event-dialog" index={2}>
            <FormGroup sx={{ marginTop: '0.5rem' }}>
              <FormControlLabel
                control={
                  <Checkbox id="anonymous"
                    checked={event.properties.anonymous}
                    onChange={handleAnonymousChange}
                  />}
                label={t('Anonymous')}
              />
              <Typography variant="caption" textAlign="left">
                {t('Check this box to keep your nickname hidden in pin details. \
Keep in mind that invitees will still see who sent the invitation.')}
              </Typography>
              <br />
              <FormControlLabel
                control={
                  <Checkbox id="private"
                    checked={event.properties.private}
                    onChange={handlePrivateChange}
                  />}
                label={t('Private')}
              />
              <Typography variant="caption" textAlign="left">
                {t('Check this box to make the pin visible only to users who accept your invitation.')}
              </Typography>
              <br />
              <FormControlLabel
                control={
                  <Checkbox id="others-can-invite"
                    checked={event.properties.othersCanInvite}
                    onChange={handleOthersCanInviteChange}
                  />}
                label={t('Others can invite')}
              />
              <Typography variant="caption" textAlign="left">
                {t('Check this box to allow other users to send invitations to your pin.')}
                <br />
                {t('If the pin is marked as private, only those who accept the invitation \
will be able to invite others; otherwise, everyone can do so.')}
              </Typography>
            </FormGroup>
          </TabPanel>
        </StyledDialogContent>
        <Stack
          spacing={0}
          direction="row"
          justifyContent="space-around"
          sx={{
            height: '4.5rem',
            padding: '1rem 1rem',
            backgroundColor: 'transparent',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            backdropFilter: backdropBlur,
            borderRadius: '10px 10px 0 0',
            boxShadow: fabBoxShadow,
          }}
        >
          <Button
            variant="contained"
            disabled={submitButtonDisabled}
            type="submit"
            sx={{ width: '5.25rem' }}
          >
            {t('Save')}
          </Button>
        </Stack>
      </form>
    </Dialog>
  )
};