import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Badge from '@mui/material/Badge';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import CenteredBox from '../ui/CenteredBox.tsx';
import FriendAvatar from './FriendAvatar.tsx';
import FriendAvatarSkeleton from './FriendAvatarSkeleton.tsx';
import NoContentAlert from '../ui/NoContentAlert.tsx';
import SearchInput from '../ui/SearchInput.tsx';

import { setPremiumInfoDialogOpen } from '../../features/app/dialogsSlice';

import {
  countCharacters,
  formatUuid,
} from '../../utils/utils';

import {
  INVITATION_MESSAGE_MAX_LENGTH,
  MAX_PIN_INVITEES,
} from '../../limits.ts';

export default function InviteFriends(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const friends = useSelector((state) => state.network.friends);
  const [filteredFriends, setFilteredFriends] = useState(friends);
  const [searchInput, setSearchInput] = useState('');

  const plan = useSelector((state) => state.account.plan);
  const premiumInfoDialogOpen = useSelector((state) => state.dialogs.premiumInfoDialogOpen);

  const handleInvitationMessageChange = (e) => {
    props.setInvitationMessageError(countCharacters(e.target.value) > INVITATION_MESSAGE_MAX_LENGTH);
    props.setInvitationMessage(e.target.value);
  };

  useEffect(() => {
    setFilteredFriends({
      type: 'FeatureCollection',
      features: friends.features.filter(feature => {
        return searchInput.trim() === '' ||
          feature.properties.username.toLowerCase().includes(searchInput.toLowerCase());
      }),
    });
  }, [friends, searchInput]);

  useEffect(() => {
    if (filteredFriends.features.length === 0) {
      props.setSubmitButtonDisabled(true);
    };
  }, [filteredFriends]);

  useEffect(() => {
    if (plan === 'free' && props.selectedFriends.length === MAX_PIN_INVITEES + 1) {
      dispatch(setPremiumInfoDialogOpen(true));
    };
  }, [props.selectedFriends.length, plan]);


  useEffect(() => {
    if (!premiumInfoDialogOpen && props.selectedFriends.length >= MAX_PIN_INVITEES + 1) {
      props.setSelectedFriends(prevSelectedFriends => prevSelectedFriends.slice(0, -1));
    };
  }, [premiumInfoDialogOpen]);

  return (
    <React.Fragment>
      <TextField
        id="message"
        value={props.invitationMessage}
        onChange={handleInvitationMessageChange}
        label={t('Message')}
        margin="dense"
        multiline
        fullWidth
        variant="standard"
        rows={3}
        helperText={`${countCharacters(props.invitationMessage)}/${INVITATION_MESSAGE_MAX_LENGTH} ` + t('characters')}
        error={props.invitationMessageError}
      />
      {!props.fetching && friends.features.length > 0 && (
        <SearchInput searchInput={searchInput} setSearchInput={setSearchInput} />
      )}
      {filteredFriends.features.length > 0 ? (
        <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
          {filteredFriends.features.map(feature => (
            <Grid item xs={4} md={4} xl={4}
              key={feature.properties.uuid}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}
            >
              {!props.fetching ? (
                <Stack direction="column">
                  <Badge
                    badgeContent={<CancelOutlinedIcon fontSize="small" />}
                    size="small"
                    onClick={() => {
                      if (!props.alreadyInvited.some(el => el.status !== 0 && el.uuid === feature.properties.uuid)) {
                        props.setSelectedFriends(prevSelectedFriends => {
                          const index = prevSelectedFriends.findIndex(friend => friend.properties.uuid === feature.properties.uuid);
                          if (index === -1) {
                            return [...prevSelectedFriends, feature];
                          } else {
                            return prevSelectedFriends.filter(friend => friend.properties.uuid !== feature.properties.uuid);
                          };
                        });
                      };
                    }}
                    sx={{
                      maxWidth: 'fit-content',
                      margin: 'auto',
                      '& .MuiBadge-badge': {
                        zIndex: 0,
                        visibility: !props.alreadyInvited.some(el => el.status !== 0 && el.uuid === feature.properties.uuid) &&
                          props.selectedFriends.some(el => el.properties.uuid === feature.properties.uuid)
                          ? 'visible' : 'hidden',
                      }
                    }}
                  >
                    <FriendAvatar user={feature} border={
                      props.alreadyInvited.some(el => el.status !== 0 && el.uuid === feature.properties.uuid) ||
                      props.selectedFriends.some(el => el.properties.uuid === feature.properties.uuid)
                    } />
                  </Badge>
                  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {feature.properties.username || formatUuid(feature.properties.uuid)}
                  </span>
                </Stack>
              ) : (
                <FriendAvatarSkeleton />
              )}
            </Grid>
          ))}
        </Grid>
      ) : (
        <CenteredBox mt={2}>
          <NoContentAlert text={t('No results!')} spacing={0} />
        </CenteredBox>
      )}
    </React.Fragment>
  );
};