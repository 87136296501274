import {
  setAlertsSnackbarOpen,
  setAlertsSnackbarSeverity,
  setAlertsSnackbarText,
} from '../features/app/alertsSnackbarSlice';
import {
  setEventInvitationsDialogOpen,
  setNetworkDialogOpen,
  setProfileDialogOpen,
  setSavedEventsDialogOpen,
  setSettingsDialogOpen,
  setUserEventsDialogOpen,
  toggleEventInvitationsDialogOpen,
  toggleNetworkDialogOpen,
  toggleProfileDialogOpen,
  toggleSettingsDialogOpen,
} from '../features/app/dialogsSlice';
import { setAuthDialogOpen } from '../features/dialogs/authDialogSlice.ts';

import { APP_URL } from '../config';

// const closeTimeout = isMobile ? 500 : 0;
const closeTimeout = 0;

const handleUserEventsClose = (dispatch) => {
  // dispatch(setBottomBarValue(1));
  dispatch(setSavedEventsDialogOpen(false));
  dispatch(setUserEventsDialogOpen(false));
};

export const handleLogin = (dispatch, resetMenus) => {
  resetMenus();
  dispatch(setAuthDialogOpen(true));
};

export const handleProfileClick = (dispatch, resetMenus) => {
  resetMenus();
  dispatch(toggleProfileDialogOpen());
  setTimeout(() => {
    dispatch(setEventInvitationsDialogOpen(false));
    dispatch(setNetworkDialogOpen(false));
    dispatch(setSettingsDialogOpen(false));
    handleUserEventsClose(dispatch);
  }, closeTimeout);
};

export const handleNetworkClick = (dispatch, resetMenus) => {
  resetMenus();
  dispatch(toggleNetworkDialogOpen());
  setTimeout(() => {
    dispatch(setEventInvitationsDialogOpen(false));
    dispatch(setProfileDialogOpen(false));
    dispatch(setSettingsDialogOpen(false));
    handleUserEventsClose(dispatch);
  }, closeTimeout);
};

export const handleInvitationsClick = (dispatch, resetMenus) => {
  resetMenus();
  dispatch(toggleEventInvitationsDialogOpen());
  setTimeout(() => {
    dispatch(setNetworkDialogOpen(false));
    dispatch(setProfileDialogOpen(false));
    dispatch(setSettingsDialogOpen(false));
    handleUserEventsClose(dispatch);
  }, closeTimeout);
};

export const handleSettingsClick = (dispatch, resetMenus) => {
  resetMenus();
  dispatch(toggleSettingsDialogOpen());
  setTimeout(() => {
    dispatch(setEventInvitationsDialogOpen(false));
    dispatch(setNetworkDialogOpen(false));
    dispatch(setProfileDialogOpen(false));
    handleUserEventsClose(dispatch);
  }, closeTimeout);
};

export const handleLogout = (dispatch, resetMenus, logoutUser, t) => {
  resetMenus();
  setTimeout(() => logoutUser(), 1);

  dispatch(setAlertsSnackbarSeverity('info'));
  dispatch(setAlertsSnackbarText(t('Logged out!')));
  dispatch(setAlertsSnackbarOpen(true));
};

export const handleShareLocationClick = async (dispatch, location, t) => {
  if (navigator.canShare) {
    await navigator.share({
      // title: APP_NAME,
      text: `${t("Here's my current location")}:`,
      url: `${APP_URL}/?location=${location}`,
    });
  } else {
    // navigator.clipboard.writeText(t("Here's my current location") + ": " + `${APP_URL}/?location=${location}`)
    navigator.clipboard.writeText(`${t("Here's my current location")}:\n${APP_URL}/?location=${location}`)
      .then(() => {
        dispatch(setAlertsSnackbarSeverity('info'));
        dispatch(setAlertsSnackbarText(t('Location copied to clipboard')));
        dispatch(setAlertsSnackbarOpen(true));
      })
      .catch((error) => {
        dispatch(setAlertsSnackbarSeverity('warning'));
        dispatch(setAlertsSnackbarText(t('Something went wrong!')));
        dispatch(setAlertsSnackbarOpen(true));
      });
  };
};